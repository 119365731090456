.footer {
    background-color: #258df5;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
      }
  
  .company-info {
    max-width: 50%;
  }
  
  .company-info h3 {
    margin-bottom: 10px;
  }
  
  .company-info p {
    margin: 5px 0;
  }
  
  .social-media {
    max-width: 30%;
    margin-right: 300px;
  }
  
  .social-media h3 {
    margin-bottom: 10px;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-around;
  }
  
  .social-icons a {
    margin: 0 10px;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;

  }
  