/* Logo Image Styles */
.logo-image {
    width: 100px; /* Adjusted width */
    height: auto; /* Maintain aspect ratio */
    opacity: 1; /* Default opacity */
    transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    animation: slide 10s infinite alternate; /* Animation for moving effect */
    border-radius: 2px; /* Round shape */
    object-fit: cover; /* Ensures the image covers the area */
}

.logo-image:hover {
    opacity: 0.4; /* Full opacity on hover */
}

/* Keyframes for moving effect */
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-5%); /* Slight left movement */
    }
    100% {
        transform: translateX(5%); /* Slight right movement */
    }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .logo-image {
        opacity: 0.7; /* Slightly less opacity for smaller screens */
    }
}

/* Header Styles */
.header {
    padding: 2px; /* Padding for the header */
    position: relative; /* Allows for absolute positioning of inner elements */
}

.header-content {
    display: flex; /* Flexbox for layout */
    justify-content: space-between; /* Space items evenly */
    align-items: center; /* Center items vertically */
}

/* Header Image Styles */
.header-image {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    opacity: 1; /* Initial opacity */
    transition: opacity 1s ease-in-out; /* Smooth transition for opacity */
    animation: slide 3s infinite alternate; /* Animation for moving effect */
}

.header-image:hover {
    opacity: 0.5; /* Change opacity on hover */
}

/* Header Navigation Styles */
.header-nav {
    display: flex; /* Flexbox for navigation */
    align-items: center; /* Center nav items */
}

.nav-list {
    list-style: none; /* Remove bullet points */
    display: flex; /* Flexbox for list items */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.nav-item {
    margin: 0 10px; /* Space between nav items */
    cursor: pointer; /* Pointer cursor for interactivity */
}

.login-button{
    background-color: rgb(186, 186, 252);
}
/* Button Styles */
.nav-item .login-button {
    font-size: 14px; /* Button font size */
    padding: 8px 20px; /* Button padding */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth hover transition */
}

/* Button Hover Effects */
.nav-item .login-button:hover {
    background-color: #007bff; /* Change background on hover */
    color: white; /* Change text color on hover */
}

/* Header Button Styles */
.header-buttons {
    display: flex; /* Flexbox for buttons */
    align-items: center; /* Center buttons vertically */
}

.lo-button, .get-started-button {
    margin-left: 10px; /* Space between buttons */
    padding: 10px 20px; /* Button padding */
    border: none; /* Remove default border */
    border-radius: 8px; /* Rounded corners */
    font-size: 16px; /* Font size for buttons */
    cursor: pointer; /* Pointer cursor for interactivity */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    display: inline-flex; /* Align icon and text nicely */
    align-items: center; /* Center items vertically */
}

.lo-button {
    background-color: #dbe7f3; /* Primary button color */
    color: white; /* White text for login button */
}

.get-started-button {
    background-color: #dee8f3; /* Success button color */
    color: white; /* White text for get started button */
}

.lo-button:hover, .get-started-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect on login */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.get-started-button:hover {
    background-color: #b4c0cd; /* Darker shade for hover effect on get started */
}

/* Header Image and Text Section */
.header-image-container {
    margin-top: 20px; /* Space between header and image */
    text-align: center; /* Center the image */
}

.header-image-container img {
    max-width: 100%; /* Make sure the image is responsive */
    height: auto; /* Maintain aspect ratio */
}

.left-header-image {
    margin-left: 200px; /* Space between the image and text */
}

.header-image {
    width: 800px; /* Adjust width based on your design */
    height: 500px; /* Maintain aspect ratio */
}

.right-header-text {
    display: flex; /* Flexbox for text */
    flex-direction: column; /* Stack the text vertically */
    align-items: flex-start; /* Align text to the left */
    margin-right: 200px; /* Space between the image and text */
}

.right-header-text h1 {
    font-size: 36px; /* Adjust font size as needed */
    font-weight: bold; /* Make it bold */
    color: #258df5; /* Match your theme color */
    line-height: 1.2; /* Control line spacing */
}

.right-header-text h2 {
    font-size: 20px; /* Adjust font size as needed */
    font-weight: normal; /* Regular font weight */
    color: #258df5; /* Match your theme color */
    margin-bottom: 10px; /* Space below the heading */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .header-content {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the left */
    }

    .nav-list {
        flex-direction: column; /* Stack navigation links vertically */
    }

    .nav-item {
        margin: 50px 0; /* Add margin between nav items */
    }

    .left-header-image, .right-header-text {
        margin-left: 0; /* Remove margin on smaller screens */
        margin-right: 0; /* Remove margin on smaller screens */
    }

    .header-image {
        width: 100%; /* Make the image responsive */
        height: auto; /* Adjust height automatically */
    }

    .right-header-text h1 {
        font-size: 28px; /* Adjust font size on smaller screens */
    }
}

.hero-section {
    position: relative; /* Context for absolute positioning */
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Prevent overflow */
  }
  
  .hero-image {
    width: 100%; /* Full width */
    height: 70%; /* Full height */
    object-fit: cover; /* Cover the area without distortion */
  }
  
  .overlay {
    position: absolute; /* Position overlay over the image */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    pointer-events: none; /* Allow clicks to go through the overlay */
  }
  
  .hero-text {
    position: absolute; /* Position over the image */
    top: 50%; /* Center vertically */
    right: 0; /* Align to the right */
    transform: translateY(-50%); /* Center the text */
    color:rgb(37, 150, 190);
text-align: right; /* Right-align the text */
    padding: 20px; /* Spacing around the text */
    font-weight: bold;
  }
  
  .hero-text h2 {
    font-size: 2rem; /* Adjust font size */
    margin: 0.5rem 0; /* Spacing */
  }
  
  .hero-text h1 {
    font-size: 2rem; /* Adjust font size */
    margin: 0; /* Remove default margin */
    line-height: 1.2; /* Line height for spacing */
  }
  
  /* Optional: Add responsiveness */
  @media (max-width: 768px) {
    .hero-text h2 {
      font-size: 1.5rem; /* Smaller size for mobile */
    }
  
    .hero-text h1 {
      font-size: 2rem; /* Smaller size for mobile */
    }
  }
  