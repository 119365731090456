.shipping-service {
    max-width: 1200px; /* Adjust width as needed */
    margin: 0 auto; /* Center the content */
    padding: 20px; /* Add padding */
    text-align: center; /* Center-align text */
}

.shipping-options {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow wrapping to new rows */
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between items */
}

.shipping-option {
    background-color: #f9f9f9; /* Light background for each option */
    border: 1px solid #ccc; /* Border for better visibility */
    border-radius: 10px; /* Rounded corners */
    padding: 15px; /* Inner padding */
    width: 30%; /* Control the width of each option */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.2s; /* Smooth transform on hover */
}

.shipping-option:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

.shipping-logo {
    max-width: 100%; /* Responsive logo */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space below logo */
}
